import '@dudojs/adminui/locale/vi'
import '@dudojs/adminui'
import './admin.scss'

// Images
// -----------------------------------------------
import './images/favicon.ico'
import './images/logo.png'
import './images/logo_white_mini.png'
import './images/no_photo.png'
import io from 'socket.io-client'

window.SELECT2_CUSTOMER = {
  language: {
    noResults: function () {
      return 'Không có dữ liệu'
    },
  },
  ajax: {
    delay: 250,
    url: '/customer/find',
    dataType: 'json',
    data: function (params) {
      return {
        query: params.term,
        page: params.page || 1,
      }
    },
    processResults: function (resp, params) {
      return {
        results: resp.data.customerList.map(customer => {
          return {
            ...customer,
            id: customer._id,
          }
        }),
        pagination: {
          more: (params.page * 20) < resp.data.totalRecord,
        },
      }
    },
  },
  templateResult: (repo) => {
    if (repo.loading) {
      return 'Đang tải...'
    }

    let container = $(`
            <div class="select2-result-repository clearfix">
                <div class="select2-result-repository__meta">
                    <div class="">${repo.name} (${repo.codeText})</div>
                    <div class="small">${repo.email} - ${repo.phone}</div>
                </div>
            </div>
        `)

    return container
  },
  templateSelection: (repo) => {
    return repo.name ? `${repo.name} (${repo.codeText})` : repo.text
  },
}

window.onNewChat = null
const initChatNoti = () => {
  if ($(document.body).hasClass('login-page')) {
    return
  }

  let chatNotification = $('#chat-notification')
  let chatNumber = chatNotification.find('.chat-number')
  let chatNotiList = chatNotification.find('.dropdown-menu')

  $.ajax({
    url: '/chat/',
    type: 'get',
    dataType: 'json',
    success: (resp) => {
      let chatHtml = ''
      if (resp && resp.status) {
        if (resp.data.length === 0) {
          chatHtml = `<div class="dropdown-item text-center no-chat">Không có chat nào</div>`
        } else {
          resp.data.forEach((chat, index) => {
            chatHtml += `             
                        ${index === 0 ? '' : '<div class="dropdown-divider"></div>'}           
                        <a href="/customer/${chat.customer[0]._id}/chat" class="dropdown-item" data-customer-id="${chat.customer[0]._id}">
                            ${chat.customer[0].name}
                            <span class="float-right"><i class="far fa-comment"></i></span>
                        </a>
                    `
          })
        }

        chatNumber.html(resp.data.length)
        chatNumber.attr('class', `badge badge-${resp.data.length === 0 ? 'secondary' : 'danger'} chat-number`)
        chatNotiList.html(chatHtml)
      } else {
        Msg.error(resp && resp.message ? resp.message : 'Có lỗi xảy ra!')
      }
    },
    error: () => {
      Msg.error('Có lỗi xảy ra!')
    },
  })

  let socket = io(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.hostname}:${window.location.port}`)
  socket.on(`chat new ${userId}`, (data) => {
    if (chatNotiList.find('.no-chat').length > 0) {
      chatNotiList.html('')
    }

    let totalChat = chatNotiList.children().length
    let newChat = chatNotiList.find(`.dropdown-item[data-customer-id="${data.customer}"]`)

    if (newChat.length === 0) {
      chatNotiList.prepend(`      
                <a href="/customer/${data.customer}/chat" class="dropdown-item" data-customer-id="${data.customer}">
                    ${data.customerName}
                    <span class="float-right"><i class="far fa-comment"></i></span>
                </a>
                ${totalChat === 0 ? '' : '<div class="dropdown-divider"></div>'}   
            `)

      chatNumber.html(+chatNumber.html() + 1)
      chatNumber.attr('class', 'badge badge-danger chat-number')
    }

    typeof window.onNewChat === 'function' && window.onNewChat(data)
  })
}

$(() => {
  initChatNoti()
})

